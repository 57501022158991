<template>
  <div>
    <div v-if="noData" class="noDataText">
      <h4>Não há termos pendentes.</h4>
    </div>
    <div>
      <ModalAssinaturaTermo
        :assinaturaTermo="assinaturaTermo"
        @resposta="respostaModalAssinatura"
      ></ModalAssinaturaTermo>
    </div>
  </div>
</template>
<!-- eslint-disable -->
<script>
import service from '../services';
import ModalAssinaturaTermo from '../components/modais/ModalAssinaturaTermo.vue';

export default {
  name: 'TermosPendentes',

  data() {
    return {
      carregando: false,
      assinaturaTermo: {},
      noData: false,
    };
  },
  methods: {
    verificaTermosObrigatorios(resposta) {
      const idEmpresa = this.$route.params.idEmpresa;
      const idAssociado = this.$route.params.idAssociado;
      service
        .post('termos-pendentes', 'list', {
          idEmpresa: idEmpresa,
          idAssociado: idAssociado,
        })
        .then((res) => {
          const obj = res.data;
          if (obj.length > 0) {
            this.noData = false;
            const objModal = {
              flag: true,
              action: 'termosPendentes',
              idsTermosPendentes: [obj[0].id_modelo],
              resposta: resposta,
            };
            this.assinaturaTermo = objModal;
          } else {
            const objModal = {
              flag: false,
              action: null,
            };
            this.assinaturaTermo = objModal;
            this.noData = true;
          }
        })
        .catch((e) => {
          throw new Error(e);
        });
    },

    respostaModalAssinatura(resposta) {
      if (resposta == 'verificarNovamente')
        this.verificaTermosObrigatorios(resposta);
      if (resposta == 'aceiteContrato') this.migracao();
    },
  },
  created() {
    this.verificaTermosObrigatorios();
  },
  components: {
    ModalAssinaturaTermo,
  },
};
</script>
<style scoped>
.noDataText {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
